import React from 'react'
import Terms from '../../../components/Legal/Terms'

const Page = ({ location }) => {
    return (
        <Terms
            language="is"
            location={location}
        />
    )
}

export default Page
