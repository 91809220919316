const Text = {
    is:
`
# Notkunarskilmálar
Vinsamlegast lestu þessa notkunarskilmála þar sem þeir eiga við um notkun þína á þjónustu Tilkynna. Ef þú samþykkir ekki þessa notkunarskilmála skaltu ekki nota þjónustuna.
Notkunarskilmálar geta breyst hvenær sem er og er áframhaldandi notkun á þjónustunni talin samþykki breytinganna. 

## Notkun þjónustu
Þjónustuna má aðeins nota í lögmætum tilgangi og á lögmætan hátt. Með því að nota þjónustuna samþykkir þú að fara að öllum gildandi lögum og reglum sem varða þessa þjónustu og notkun hennar. Þú staðfestir að allar upplýsingar sem þú veitir í gegnum þjónustuna eru sannar og réttar, eftir þinni bestu vitund, og ætlaðar til að upplýsa um misferli eða ámælisverða háttsemi í starfsemi lögaðila.

Með því að nota kerfið þá samþykkir þú að hlaða ekki upp né senda eða dreifa, skaðlegum hugbúnaði sem ætlað er að breyta tölvukerfi eða gögnum, svo sem tölvuvírusum og ormum, eða til að fá óheimilan aðgang að þjónustunni, öðrum notendum eða öðrum kerfum sem eru tengd þjónustunni. 
## Persónuupplýsingar
Þjónusta Tilkynna er þróuð til að standa vörð um nafnleynd og vernda gögn þess sem sendir skilaboð ásamt því að gera notendum kleift að eiga nafnlaus samskipti sín á milli. Tilkynna heldur ekki utan um lýsigögn, þar á meðal IP tölu þína, sem gæti leitt í ljós hver þú ert. 
## Ábyrgð
Notkun þjónustunnar er á eigin ábyrgð notanda. Tilkynna er undanskilið, að því marki sem lög leyfa, allri ábyrgð sem kann að myndast sem afleiðing af notkun þessarar þjónustu. Með því að nota þjónustu Tilkynna, samþykkir þú að gera engar kröfur í bætur til Tilkynna / Slidesome ehf. vegna skaða sem gæti hlotist af notkun þjónustunnar. Þar með talið vegna niðurhals, hlaða upp gögnum, vegna aðgangs að gögnum, upplýsingum eða öðru efni sem hægt er að nálgast í gegnum Tilkynna. 
## Gildandi lög
Notendaskilmálum er stýrt af og túlkaðir í samræmi við íslensk lög.
`,
    en:
`
# Terms of Use
Please read these Terms of Use as they apply to your use of Tilkynna. If you do not agree to these Terms of Use then do not use the service. Terms of use are subject to change at any time and continued use of the service is deemed acceptance of the changes.

## Use of services
The Service may only be used for lawful purposes and in a lawful manner. By using the service, you agree to comply with all applicable laws and regulations regarding this service and its use. You acknowledge that all information you provide through the Service is true and correct, to the best of your knowledge, and is intended to inform about misconduct or reprehensible conduct in the activities of legal entities.

By using the system, you agree not to upload or transmit or distribute malicious software intended to modify computer systems or data, such as computer viruses or worms, or to gain unauthorized access to the service, other users or other systems that are related to the service.

## Personal information
Reporting services are designed to protect anonymity and protect the data of the sender, as well as enable users to communicate anonymously with each other. Also, do not report metadata, including your IP address, that could reveal who you are.


## Liability
Use of the service is at the user's own risk. Notify, to the extent permitted by law, any liability that may arise as a result of the use of this service. By using Tilkynna's services, you agree to make no claims for compensation to Tilkynna / Slidesome ehf. due to damage that may result from the use of the service. This includes downloading, uploading data, accessing data, information or other content that can be accessed through Tilkynna.

## Applicable law
Terms of use are governed by and interpreted in accordance with Icelandic law

`
}

export default Text
